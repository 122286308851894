import React, { useEffect, useRef, useState } from 'react';

const FadeInSection = (props: any) => {
  const [isVisible, setVisible] = useState(true);
  const domRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!domRef.current) {
      return;
    }
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => setVisible(entry.isIntersecting));
    });

    observer.observe(domRef.current);

    return () => observer.disconnect();
  }, []);
  return (
    <div
      className={`fade-in-section ${isVisible ? 'is-visible' : ''} ${props.className}`}

      ref={domRef}
    >
      {props.children}
    </div>
  );
}

export default FadeInSection