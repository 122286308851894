import React from 'react'
import { Helmet } from 'react-helmet';
import Contact from '../components/Contact';
import Hero from '../components/Hero'
import Footer from '../components/utility/Footer'
import Navbar from '../components/utility/Navbar';
import listIcon from "../images/listbullet.svg";

const mail = 'aW5mb0Bob2x6YmF1bXVlbGxlci5kZQ==';

const handleButtonClick = () => {
  window.open(`mailto:${atob(mail)}`, '_blank');
}

const contactData =
  <>
    <div className='block-headline-container'>
      <img className='block-icon' src={listIcon} alt="Diagonal steigender Strich" />
      <span className='block-headline'>Adresse</span>
    </div>
    <p className='block-content'>
      Zimmerei Müller GmbH<br />
      Lichtestr. 1<br />
      73667 Kaisersbach<br />
    </p>
    <div className='block-headline-container'>
      <img className='block-icon' src={listIcon} alt="Diagonal steigender Strich" />
      <span className='block-headline'>Kontakt</span>
    </div>
    <p className='block-content'>
      <a href="tel:07184-2761">Tel:  07184 2761</a><br />
      <a href="tel:07184-2114">Fax: 07184 2114</a>
    </p>
    <div className='block-headline-container'>
      <img className='block-icon' src={listIcon} alt="Diagonal steigender Strich" />
      <span className='block-headline'>E-Mail</span>
    </div>
    <p className='block-content'><button className='contact-btn' onClick={() => handleButtonClick()}>&#x69;&#x6e;&#x66;&#x6f;&#x40;&#x68;&#x6f;&#x6c;&#x7a;&#x62;&#x61;&#x75;&#x6d;&#x75;&#x65;&#x6c;&#x6c;<span className='highlight' hidden>zimmer</span>&#x65;&#x72;&#x2e;&#x64;&#x65;</button></p>
  </>


const KontaktPage = () => {
  return (
    <>
      <Helmet title="Zimmerei Müller - Kontakt">
        <meta property="og:title" content="Zimmerei Müller - Kontakt" />
        <meta
          property="og:description"
          content="Inspiriert, mit uns zu arbeiten? Neugierig, mehr über unsere Arbeit zu erfahren? Bitte sprechen Sie uns an!"
        />
        <meta property="og:url" content="https://holzbaumueller.de/kontakt" />
        <meta
          name="description"
          content="Inspiriert, mit uns zu arbeiten? Neugierig, mehr über unsere Arbeit zu erfahren? Bitte sprechen Sie uns an!"
        />
      </Helmet>
      <Navbar />
      <main>
        <Hero headline="Kontakt" text='Inspiriert, mit uns zu arbeiten? Neugierig, mehr über unsere Arbeit zu erfahren? Sprechen Sie uns an!' blockContent={contactData} alt="" />
        <Contact />
      </main>
      <Footer />
    </>
  )
}

export default KontaktPage