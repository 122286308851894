import React from 'react'
import "../style/components/contact.scss";
import FadeInSection from './utility/FadeInSection';

const Contact = () => {

  const mail = 'aW5mb0Bob2x6YmF1bXVlbGxlci5kZQ==';

  const handleButtonClick = () => {
    window.open(`mailto:${atob(mail)}`, '_blank');
  }

  return (
    <section id="contact">
      <div className='wrapper'>
        <FadeInSection>
          <h2>Ihr Spezialist, wenn es um's bauen mit Holz geht!</h2>
          <p className='contact-text lines-wrapper'>
            Wir sehen uns als kompetenter Partner für Architekten und Planungsbüros, aber genauso für private Bauherren und Unternehmer.
            <hr className='horizontal-line' />
            <hr className='rising-line' />
            <button className='btn' onClick={() => handleButtonClick()}>Jetzt kontaktieren</button>
          </p>
        </FadeInSection>
      </div>
    </section>
  )
}

export default Contact